import axios from 'axios';

const DISCORD_WEBHOOK_URL = process.env.REACT_APP_DISCORD_WEBHOOK_URL;

const sendToDiscord = async (message) => {
  try {
    const response = await axios.post(DISCORD_WEBHOOK_URL, message, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error sending message to Discord:', error);
    throw error;
  }
};

export default sendToDiscord;
        