import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

const PaymentForm = ({ clientSecret, plan, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('initial'); // 'initial', 'success', 'error'

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setError(null);
    setPaymentStatus('initial');

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }

      const { error: paymentError, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: window.location.origin,
        },
        redirect: 'if_required',
      });

      if (paymentError) {
        throw paymentError;
      }

      if (paymentIntent.status === 'succeeded') {
        setPaymentStatus('success');
        setTimeout(() => {
          onSuccess();
        }, 2000); // Show success message for 2 seconds before closing
      }
    } catch (err) {
      setError(err.message);
      setPaymentStatus('error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRetry = () => {
    setError(null);
    setPaymentStatus('initial');
    elements?.update({ complete: false });
  };

  if (paymentStatus === 'success') {
    return (
      <div className="payment-success">
        <div className="success-icon">✓</div>
        <h3>Payment Successful!</h3>
        <p>{plan.credits} credits have been added to your account</p>
      </div>
    );
  }

  if (paymentStatus === 'error') {
    return (
      <div className="payment-error-container">
        <div className="error-icon">⚠️</div>
        <h3>Payment Failed</h3>
        <p>{error}</p>
        <button className="retry-button" onClick={handleRetry}>
          Try Again
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {error && <div className="payment-error">{error}</div>}
      <button 
        className="pay-button" 
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? 'Processing...' : `Pay $${plan.price.toFixed(2)}`}
      </button>
    </form>
  );
};

export default PaymentForm;