// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Add this import

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBW56pZ4AJmxU6PqbM8t1_OeGDTabx35mI",
  authDomain: "wedifyai.firebaseapp.com",
  projectId: "wedifyai",
  storageBucket: "wedifyai.appspot.com",
  messagingSenderId: "215177093936",
  appId: "1:215177093936:web:9ab76425c4b828208e9ef5",
  measurementId: "G-8NQGZ8H0SQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

export { auth, analytics, db }; // Add db to the exports