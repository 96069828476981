import React from 'react';
import Footer from './Footer';
import './PrivacyPolicy.css';
import PrivacyPolicyContent from './PrivacyPolicyContent.js';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-wrapper">
            <div className="privacy-policy-container">
                <h1>{PrivacyPolicyContent[0].title}</h1>
                <p className="effective-date">Effective Date: {PrivacyPolicyContent[0].effectiveDate}</p>

                <section>
                    <p>{PrivacyPolicyContent[0].introduction}</p>
                </section>

                {PrivacyPolicyContent[0].sections.map((section, index) => (
                    <section key={index}>
                        <h2>{section.title}</h2>
                        <p>{section.content}</p>
                        {section.subtitles && section.subtitles.map((subtitle, subIndex) => (
                            <div key={subIndex}>
                                <strong>{subtitle.subtitle}</strong>
                                {Array.isArray(subtitle.content) ? (
                                    subtitle.content.map((item, itemIndex) => (
                                        <div key={itemIndex}>
                                            <strong>{item.title}</strong>
                                            <p>{item.content}</p>
                                        </div>
                                    ))
                                ) : (
                                    <p>{subtitle.content}</p>
                                )}
                            </div>
                        ))}
                    </section>
                ))}
            </div>
            <Footer />
          </div>
  );
};

export default PrivacyPolicy;
