import React, { useState, useCallback, useEffect } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, sendPasswordResetEmail } from 'firebase/auth';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from './utils/firebase';
import CryptoJS from 'crypto-js';
import './LoginPage.css';
import { IoArrowBack } from "react-icons/io5";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [locationName, setLocationName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [userType, setUserType] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [accountType, setAccountType] = useState('');

  const encrypt = (plaintext, key) => {
    try {
      if (!key) {
        throw new Error('Encryption key is missing');
      }
      
      const keyBytes = CryptoJS.enc.Hex.parse(key);
      const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      
      return encrypted.toString();
    } catch (error) {
      console.error('Encryption error:', error);
      throw error;
    }
  };

  const generateToken = useCallback(() => {
    try {
      const externalId = '221312312'; // Hardcoded external ID
      const utcTime = new Date().toISOString();
      const combinedString = `${externalId}----------${utcTime}`;
      

      const secretKey = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;
      if (!secretKey) {
        throw new Error('Secret key is not configured');
      }

      const encryptedId = encrypt(combinedString, secretKey);
      
      return encryptedId;
    } catch (error) {
      console.error('Error generating auth token:', error);
      setError(error.message);
      return null;
    }
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      const token = generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      // Fetch user data from Firestore
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
      
      navigate('/');
    } catch (error) {
      console.error('Sign in error:', error);
      setError(error.message);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(null);
    
    if (!acceptedTerms) {
      setError('Please accept the Terms of Service to continue');
      return;
    }
    
    try {

      
      // Prepare user data for API
      const apiUserData = {
        emailAddress: email,
        venderType: userType === 'vendor' ? 'vendor' : null,
        companyName: userType === 'vendor' ? companyName : null,
        phoneNumber: userType === 'vendor' ? phoneNumber : null,
        location: userType === 'vendor' ? locationName : null, // Make sure this is correct
        wedify_user_type: userType === 'vendor' ? 'vender' : 'couple'
      };

      const token = generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      // Send data to API using the full URL
      const apiResponse = await fetch('https://wedify.live/api/login/addUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': token
        },
        body: JSON.stringify(apiUserData),
      });

      if (!apiResponse.ok) {
        const errorText = await apiResponse.text();
        console.error('API Error response:', errorText);
        throw new Error(`Failed to add user to API: ${apiResponse.status} ${errorText}`);
      }

      const responseData = await apiResponse.json();
      

      // Proceed with Firebase signup
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      
      const userData = {
        email,
        userType,
      };

      if (userType === 'vendor') {
        userData.companyName = companyName;
        userData.locationName = locationName;
        userData.phoneNumber = phoneNumber;
        await updateProfile(userCredential.user, { displayName: companyName });
       
      } else {
        await updateProfile(userCredential.user, { displayName: email.split('@')[0] });
        
      }

      await setDoc(doc(db, "users", userCredential.user.uid), userData);
    

      // Send Firebase UID to update API using the email in the header
      const updateApiResponse = await fetch('https://wedify.live/api/login/users/modify/email', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000',
          'email': email,
          'X-Auth-Token': token
        },
        body: JSON.stringify({
          externalClientId: userCredential.user.uid,
          authenticationService: "firebase"
        }),
      });

      if (!updateApiResponse.ok) {
        const updateErrorText = await updateApiResponse.text();
        console.error('Update API Error response:', updateErrorText);
        throw new Error(`Failed to update user in API: ${updateApiResponse.status} ${updateErrorText}`);
      }

      const updateResponseData = await updateApiResponse.json();
      

      navigate('/');
    } catch (error) {
      console.error('Sign up error:', error);
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    try {
      const token = generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Check your inbox.');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAccountTypeSelect = (type) => {
    setUserType(type);
    setIsSignUp(true);
    setError(null);
  };


  const renderForm = () => {
    return (
      <div className="login-content">
        <div className="account-type-container">
          <div className="dots-background"></div>
          {!userType ? (
            <div className="account-type-options">
              <h2>Choose Account Type</h2>
              <button
                className="account-type-button"
                onClick={() => handleAccountTypeSelect('couple')}
              >
                Couple
              </button>
              <button
                className="account-type-button"
                onClick={() => handleAccountTypeSelect('vendor')}
              >
                Vendor
              </button>
            </div>
          ) : (
            <div className="account-type-options">
              <div className="back-arrow" onClick={() => {
                setUserType(null);
                setError(null); // Clear error message when going back
              }}>
                <IoArrowBack size={24} />
              </div>
              <form onSubmit={isSignUp ? handleSignUp : handleSignIn}>
                <h2>{isSignUp ? `Sign Up as ${userType === 'couple' ? 'a Couple' : 'a Vendor'}` : `${userType === 'couple' ? 'Couple' : 'Vendor'} Login`}</h2>
                
                {isSignUp && userType === 'vendor' && (
                  <>
                    <div className="form-group">
                      <label htmlFor="companyName">Company Name</label>
                      <input
                        id="companyName"
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="locationName">Location Name</label>
                      <input
                        id="locationName"
                        type="text"
                        value={locationName}
                        onChange={(e) => setLocationName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input
                        id="phoneNumber"
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                    </div>
                  </>
                )}
                
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                
                {isSignUp && (
                  <div className="form-group checkbox-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={acceptedTerms}
                        onChange={(e) => setAcceptedTerms(e.target.checked)}
                      />
                      <span>I accept the <Link to="/terms-of-service" target="_blank">Terms of Service</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link></span>
                    </label>
                  </div>
                )}
                
                <div className="form-actions">
                  <button type="submit" className="login-button">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
                  
                  {!isSignUp && (
                    <button type="button" onClick={handleForgotPassword} className="forgot-password-button">
                      Forgot Password?
                    </button>
                  )}
                  
                  <button type="button" onClick={() => setIsSignUp(!isSignUp)} className="switch-button">
                    {isSignUp ? 'Already have an account? Sign In' : "Create Account"}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="login-page-container">
      <div className="login-page">
        {error && <p className="error">{error}</p>}
        {renderForm()}
      </div>
    </div>
  );
};

export default LoginPage;
