import React from 'react';
import './Spinner.css';

const Spinner = ({ text = 'Loading...', color = '#dcc282' }) => {
  return (
    <div className="spinner-container">
      <div 
        className="spinner" 
        style={{ 
          borderColor: `${color}33`,
          borderTopColor: color
        }}
      ></div>
      <p className="spinner-text" style={{ color: color }}>{text}</p>
    </div>
  );
};

export default Spinner;
