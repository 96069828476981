const PrivacyPolicyContent = [
{
    title: "Privacy Policy",
    effectiveDate: "October 10th, 2024",
    introduction: "At Wedify, we are deeply committed to safeguarding your privacy and ensuring that your personal information is handled with the utmost care and security. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you access or use our application and services. By engaging with Wedify, you consent to the practices described in this policy. If you do not agree with our policies and practices, please discontinue the use of our services.",
    sections: [
      {
        title: "1. Introduction",
        content: "Wedify (\"we,\" \"us,\" or \"our\") is an innovative platform designed to revolutionize the wedding planning experience by connecting couples with wedding vendors through AI-generated designs and a sophisticated matching system. Our services enable couples to create their dream weddings while allowing vendors to showcase their unique offerings. This Privacy Policy is intended to inform you about the types of information we may collect from you or that you may provide when you use our application, as well as our practices for collecting, using, maintaining, protecting, and disclosing that information."
      },
      {
        title: "2. Information We Collect",
        content: "We collect several types of information from and about users of our services, including personal information, usage data, and content you provide.",
        subtitles: [
          {
            subtitle: "Personal Information",
            content: [
              {
                title: "Couples:",
                content: "When you register as a couple planning your wedding, we may collect personal information such as your name, email address, phone number, and other contact details. We also gather information about your wedding preferences, including design themes, color schemes, preferred vendors, and any other details you choose to share that help us tailor our services to your needs."
              },
              {
                title: "Vendors:",
                content: "If you are a vendor offering wedding-related services, we collect information pertinent to your business. This includes your business name, contact information, areas of specialization, and details about the products or services you offer. We may also collect images and descriptions of your inventory, certifications, pricing information, and any other data that helps us connect you with suitable clients."
              }
            ]
          },
          {
            subtitle: "Usage Data",
            content: "As you navigate and interact with our application, we may automatically collect certain information about your equipment, browsing actions, and usage patterns. This data helps us understand how users engage with our services and may include details such as the pages or features you access, the time and date of your visits, the amount of time spent on specific pages, and other diagnostic data. We may also collect information about the device you use to access our services, including the type of device, operating system, browser type, and unique device identifiers."
          },
          {
            subtitle: "Cookies and Tracking Technologies",
            content: "We utilize cookies and similar tracking technologies to enhance your experience on our platform. Cookies are small data files stored on your device that help us remember your preferences and understand how you use our services. They enable us to personalize your experience, keep you logged in, and provide features tailored to your interests. You have the option to disable cookies through your browser settings, but doing so may limit your ability to use certain features of our application."
          },
          {
            subtitle: "Images and Content",
            content: "Our services involve the handling of visual content, including images and designs. We collect and store photographs, designs, and other visual content that you upload or generate using our AI tools. This includes images of wedding designs, vendor inventory items, and any other visual materials you choose to provide. We process this content to facilitate our matching services, allowing couples and vendors to connect based on shared aesthetic preferences and capabilities."
          }
        ]
      },
      {
        title: "3. How We Use Your Information",
        content: "We use the information we collect about you or that you provide to us for various purposes aimed at delivering and improving our services.",
        subtitles: [
          {
            subtitle: "Service Delivery and Personalization",
            content: "Your information allows us to provide the core functionalities of our platform. For couples, we use your personal and preference information to generate personalized wedding designs and to connect you with vendors who can bring your vision to life. For vendors, your business information and inventory details enable us to showcase your offerings to couples whose preferences align with your services. By understanding your needs and interests, we can tailor our services to enhance your experience on our platform."
          },
          {
            subtitle: "Communication and Customer Support",
            content: "We may use your contact information to communicate with you about your account, respond to inquiries, provide updates on our services, and send administrative information. This includes notifying you of changes to our services, policies, or terms, as well as providing customer support when you encounter issues or have questions."
          },
          {
            subtitle: "Marketing and Promotional Activities",
            content: "With your consent, we may send you newsletters, marketing materials, and promotional offers that we believe may be of interest to you. These communications aim to inform you about new features, upcoming events, or special promotions. You have the right to opt-out of receiving marketing communications at any time by following the unsubscribe instructions provided in the email or by contacting us directly."
          },
          {
            subtitle: "AI Matching System and Analytics",
            content: "We process the information you provide, including images and preferences, through our AI algorithms to facilitate our matching services. The AI analyzes your inputs to connect couples with vendors that best suit their needs and to generate design recommendations. We also use this data to improve the accuracy and efficiency of our AI systems, ensuring better matches and more personalized experiences over time."
          },
          {
            subtitle: "Improvement and Development of Services",
            content: "Usage data and feedback help us understand how users interact with our platform, allowing us to identify areas for improvement. We analyze this information to enhance existing features, develop new functionalities, and optimize the overall performance of our services. This continuous improvement ensures that we meet the evolving needs of our users."
          },
          {
            subtitle: "Legal Compliance and Protection of Rights",
            content: "We may process your information to comply with legal obligations, such as responding to lawful requests from public authorities or enforcing our terms and conditions. Additionally, we use your data to protect our rights, privacy, safety, or property, and that of our users and others."
          }
        ]
      },
      {
        title: "4. How We Share Your Information",
        content: "We are committed to maintaining your trust, and we want you to understand when and with whom we may share your information.",
        subtitles: [
          {
            subtitle: "Sharing Between Couples and Vendors",
            content: "A fundamental aspect of our service is facilitating connections between couples and vendors. To achieve this, we share relevant information provided by couples, such as design preferences and contact details, with vendors who match their criteria. Similarly, we present vendor profiles, including business information and inventory offerings, to couples whose preferences align with the vendors' services. This mutual sharing is essential for enabling meaningful interactions and successful collaborations."
          },
          {
            subtitle: "Third-Party Service Providers",
            content: "We engage third-party companies and individuals to perform services on our behalf, such as hosting, data analysis, payment processing, information technology services, customer service, and marketing assistance. These service providers have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose."
          },
          {
            subtitle: "Legal Requirements and Protection",
            content: "We may disclose your information if required to do so by law or in response to valid requests by public authorities, such as courts or government agencies. We may also share your information when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request."
          },
          {
            subtitle: "Business Transfers",
            content: "In the event of a merger, acquisition, reorganization, sale of assets, or bankruptcy, your personal information may be transferred as part of the transaction. We will notify you via email and/or a prominent notice on our platform of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information."
          },
          {
            subtitle: "Aggregated or De-Identified Data",
            content: "We may share aggregated or de-identified information that cannot reasonably be used to identify you. This data may be used for industry analysis, demographic profiling, marketing, and advertising, and other business purposes."
          }
        ]
      },
      {
        title: "5. Data Security",
        content: "The security of your personal information is of paramount importance to us. We employ a combination of administrative, technical, and physical security measures designed to protect your information from unauthorized access, use, alteration, and disclosure.",
        subtitles: [
          {
            subtitle: "Encryption and Secure Communication",
            content: "We use Secure Sockets Layer (SSL) encryption technology to protect data transmitted to and from our application. This ensures that all data exchanged between your device and our servers remains confidential and integral."
          },
          {
            subtitle: "Access Controls and Authentication",
            content: "Access to personal information is restricted to authorized personnel who require access to perform their job functions. We implement authentication measures, such as passwords and two-factor authentication, to prevent unauthorized access to your account."
          },
          {
            subtitle: "Data Storage and Backup",
            content: "Your data is stored on secure servers hosted by reputable third-party providers that comply with industry security standards. Regular backups are performed to prevent data loss, and we have disaster recovery plans in place to maintain service continuity."
          },
          {
            subtitle: "Monitoring and Testing",
            content: "We regularly monitor our systems for potential vulnerabilities and attacks. Security assessments and penetration testing are conducted periodically to identify and address potential security risks proactively."
          },
          {
            subtitle: "Limitations",
            content: "Despite our efforts to protect your personal information, no method of transmission over the internet or method of electronic storage is entirely secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security. You also play a vital role in security by keeping your account credentials confidential and notifying us immediately of any unauthorized use of your account."
          }
        ]
      },
      {
        title: "6. Payment",
        content: "At Wedify, we prioritize the security of your payment information. We partner with Stripe, a trusted and PCI-DSS-compliant payment processor, to handle all transactions securely. Stripe ensures your payment data is encrypted and processed in accordance with the highest industry standards. Wedify does not store or access your full payment details; instead, all sensitive payment data is handled exclusively by Stripe to ensure secure processing. Additionally, we implement robust security measures, including SSL encryption for all data transmissions, secure authentication protocols, and regular system monitoring, to protect your personal and financial information. By using Stripe and maintaining strict data protection practices, we ensure your payment information remains private and secure at all times."
      }, 
      {
        title: "7. Changes to This Privacy Policy",
        content: "We may update our Privacy Policy from time to time to reflect changes in our practices, technologies, legal requirements, and other factors. When we make changes to this Privacy Policy, we will update the \"Effective Date\" at the top of the policy and post the new policy on our website. If the changes are significant, we may provide more prominent notice, such as a notification on our website or sending you an email notification. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information. Your continued use of our services after the posting of changes constitutes your acceptance of such changes."
      }
    ]
}

];

export default PrivacyPolicyContent;

