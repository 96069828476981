const TermsOfServiceContent = [
    {
        title: "Terms of Service",
        effectiveDate: "September 19th, 2024",
        introduction: "Please read these Terms of Service (\"Terms\") carefully before using Wedify (\"we,\" \"us,\" or \"our\") services. By accessing or using our application and services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not access or use our services.",
        sections: [
            {
                title: "1. Introduction",
                content: "Wedify is an innovative platform designed to connect couples planning their weddings with vendors through AI-generated wedding designs and a sophisticated matching system. Our services include image generation, vendor matching, and other tools to enhance the wedding planning experience."
            },
            {
                title: "2. Eligibility",
                content: "By using our services, you represent and warrant that:",
                subtitles: [
                    {
                        subtitle: "Eligibility Requirements",
                        content: [
                            {
                                subtitle: "Eligibility Requirements",
                                list: [
                                    " You are at least 18 years of age or the legal age of majority in your jurisdiction.",
                                    " You have the legal capacity and authority to enter into these Terms.",
                                    " All registration information you submit is accurate and truthful."
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                title: "3. Account Registration",
                subtitles: [
                    {
                        subtitle: "a. User Accounts:",
                        content: "To access certain features, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account."
                    },
                    {
                        subtitle: "b. Vendor Accounts:",
                        content: "Vendors must provide additional information, including business details and inventory offerings. Vendors are responsible for ensuring that all provided information is accurate and up-to-date."
                    }
                ]
            },
            {
                title: "4. Services Provided",
                subtitles: [
                    {
                        subtitle: "a. Image Generation:",
                        content: "Our platform allows users to generate wedding design images using AI technology. These images are intended for personal use in planning and conceptualizing wedding ideas."
                    },
                    {
                        subtitle: "b. Vendor Matching",
                        content: "We use a recognition system to identify items within generated images to match couples with vendors who offer similar inventory and services."
                    },
                    {
                        subtitle: "c. Content Organization:",
                        content: "Generated and uploaded content is categorized and organized to enhance user experience and facilitate connections between couples and vendors."
                    }
                ]
            },
            {
                title: "5. User Conduct",
                content: "By using our services, you agree not to:",
                subtitles: [
                    {
                        subtitle: "Prohibited Actions",
                        list: [
                             " Violate any applicable laws or regulations." ,
                             " Infringe upon the rights of others, including intellectual property rights." ,
                             " Upload or share content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable." ,
                             " Engage in unauthorized access or use of our systems or data." ,
                             " Interfere with or disrupt the integrity or performance of our services." 
                        ]
                    }
                ]
            },
            {
                title: "6. Intellectual Property Rights",
                subtitles: [
                    {
                        subtitle: "a. Ownership:",
                        content: "All content, features, and functionality on our platform, including but not limited to text, graphics, logos, images, and software, are owned by Wedify or our licensors and are protected by intellectual property laws."
                    },
                    {
                        subtitle: "b. License to Users:",
                        content: "We grant you a limited, non-exclusive, non-transferable license to access and use our services for personal or business purposes as intended through the normal functionality of the platform."
                    },
                    {
                        subtitle: "c. User-Generated Content:",
                        content: "By uploading or creating content on our platform, you grant Wedify a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content for the purpose of providing and improving our services."
                    },
                    {
                        subtitle: "d. Restrictions:",
                        content: "You may not:",
                        list: [
                            "Modify, copy, distribute, or create derivative works based on our services." ,
                            "Reverse engineer or attempt to extract the source code of our software." ,
                            "Use any automated systems or software to access our services without our permission."
                        ]
                    }
                ]
            },
            {
                title: "7. Third-Party Services",
                content: "Our services may integrate or interact with third-party services (e.g., APIs for image generation). While we strive to ensure compatibility and compliance, we are not responsible for the content, policies, or practices of third-party services."
            },
            {
                title: "8. Payments and Fees",
                subtitles: [
                    {
                        subtitle: "a. Subscription and Fees",
                        content: "Certain features of our services may require payment of fees. All fees are in USD and are non-refundable unless otherwise stated."
                    },
                    {
                        subtitle: "b. Payment Information",
                        content: "You must provide accurate billing information. By providing payment details, you authorize us to charge the applicable fees to your payment method."
                    },
                    {
                        subtitle: "c. Changes in Fees",
                        content: "We reserve the right to change our fees at any time. We will provide notice of fee changes by posting the updated fees on our platform or through other communication."
                    }
                ]
            },
            {
                title: "9. Disclaimers",
                subtitles: [
                    {
                        subtitle: "a. No Warranty",
                        content: "Our services are provided as is and as available without warranties of any kind, either express or implied. We do not warrant that:",
                        list: [
                            "The services will meet your requirements." ,
                            "The services will be uninterrupted, timely, secure, or error-free." ,
                            "Any results obtained from the use of the services will be accurate or reliable." 
                        ]
                    },
                    {
                        subtitle: "b. Vendor Relationships",
                        content: "Wedify is not a party to any agreements entered into between couples and vendors. We do not endorse any vendors, and we are not responsible for the actions, products, or services of vendors."
                    }
                ]
            },
            {
                title: "10. Limitation of Liability",
                content: "To the fullest extent permitted by law, in no event shall Wedify, its affiliates, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses resulting from:",
                subtitles: [
                    {
                        subtitle: "Liability Limitations",
                        list: [
                            "Your access to or use of or inability to access or use the services." ,
                            "Any conduct or content of any third party on the services." ,
                            "Any content obtained from the services." ,
                            "Unauthorized access, use, or alteration of your transmissions or content." 
                        ]
                    }
                ]
            },
            {
                title: "11. Indemnification",
                content: "You agree to defend, indemnify, and hold harmless Wedify and its affiliates, officers, agents, and employees from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses arising from:",
                subtitles: [
                    {
                        subtitle: "Indemnification Scope",
                        list: [
                             "Your use of and access to the services." ,
                             "Your violation of any term of these Terms." ,
                             "Your violation of any third-party right, including without limitation any intellectual property or privacy right." 
                        ]
                    }
                ]
            },
            {
                title: "12. Termination",
                content: "We may terminate or suspend your account and access to our services immediately, without prior notice or liability, for any reason, including but not limited to your breach of these Terms. Upon termination, your right to use the services will cease immediately. If you wish to terminate your account, you may discontinue using the services."
            },
            {
                title: "13. Governing Law",
                content: "These Terms shall be governed and construed in accordance with the laws of Delaware, without regard to its conflict of law provisions."
            },
            
            {
                title: "14. Changes to Terms",
                content: "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any changes by posting the new Terms on our platform and updating the Effective Date. By continuing to access or use our services after any revisions become effective, you agree to be bound by the updated Terms. If you do not agree to the new Terms, you must stop using the services."
            },
            {
                title: "15. Miscellaneous",
                subtitles: [
                    {
                        subtitle: "a. Entire Agreement",
                        content: "These Terms constitute the entire agreement between you and Wedify regarding the use of our services and supersede any prior agreements."
                    },
                    {
                        subtitle: "b. Severability",
                        content: "If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect."
                    },
                    {
                        subtitle: "c. Waiver",
                        content: "No waiver of any term shall be deemed a further or continuing waiver of such term or any other term."
                    }
                ]
            },
            {
                title: "16. Contact Information",
                content: "If you have any questions about these Terms, please contact us at:",
                subtitles: [
                    {
                        subtitle: "Contact Details",
                        list: [
                            "Email: admin@wedify.ai" 
                        ]
                    }
                ]
            },
            {
                title: "Acknowledgment",
                content: "By using Wedify's services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service."
            }
        ]
    }
];

export default TermsOfServiceContent;