import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const openEmailClient = (event) => {
    event.preventDefault();
    
    var email = 'admin@wedify.ai';
    var subject = 'Support';
    var body = 'Hello Wedify Support,\n\nI need assistance with...';

    // Attempt to open Gmail compose window
    var gmailUrl = 'https://mail.google.com/mail/?view=cm&fs=1&to=' + encodeURIComponent(email) + '&su=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    var gmailWindow = window.open(gmailUrl, '_blank');

    // If Gmail window fails to open, fall back to mailto link
    setTimeout(function() {
        if (!gmailWindow || gmailWindow.closed || typeof gmailWindow.closed == 'undefined') {
            var mailtoLink = 'mailto:' + email + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
            window.location.href = mailtoLink;
        }
    }, 500);
  }

  return (
    <footer className="footer">
      <div className="footer-content">
        
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/design">Design</Link></li>
            <li><Link to="/explore">Explore</Link></li>
            <li><Link to="/connect">Connect</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Legal</h3>
          <ul>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/pricing-plan">Pricing Plan</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Connect With Us</h3>
          <div className="social-icons">
            <a href="https://facebook.com/people/Wedifyai/61565660383511" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://instagram.com/wedify.ai" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://twitter.com/wedify_ai" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <button className="email-button" title="Contact Support" onClick={openEmailClient}>
              <img 
                src="https://cdn.prod.website-files.com/66de97dd26a9a3bfdeb7fe4b/66de97dd26a9a3bfdeb7fe97_email-icon-white.svg" 
                alt="Email"
                className="email-icon"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Wedify, Inc. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;