import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import Footer from './Footer';
import LandingMiddle from './LandingMiddle';
import LandingHero from './LandingHero';

const Home = () => {
  const [showScroll, setShowScroll] = useState(true);
  const landingMiddleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowScroll(false);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the LandingMiddle is visible
    );

    if (landingMiddleRef.current) {
      observer.observe(landingMiddleRef.current);
    }

    return () => {
      if (landingMiddleRef.current) {
        observer.unobserve(landingMiddleRef.current);
      }
    };
  }, []);

  return (
    <div className="home-wrapper">
      <LandingHero showScroll={showScroll} />
      <Footer />
    </div>
  );
};

export default Home;