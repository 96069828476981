const PricingPlanContent = [
    {
        title: "Pricing Plan & Credits",
        effectiveDate: "September 19th, 2024",
        introduction: "*** SUBJECT TO CHANGE ***",
        sections: [
            {
                title: "1. How Credits Work",
                content: "Credits are the currency used within Wedify to generate AI wedding designs. Each generation consumes a 30 credits based on the complexity and type of design requested. Every new user starts with 600 complimentary credits. Our credit-based system ensures flexibility and transparency in how you use our AI-powered wedding design services.",
                subtitles: [
                    {
                        subtitle: "Credit Consumption",
                        content: "Different design generations consume varying amounts of credits:",
                        tableHeaders: ["Plan", "Credits", "Price"],
                        tableData: [
                            ["Starter Plan", "600", "Included with registration"],
                            ["Basic Plan", "600", "$1.99"],
                            ["Standard Plan", "1200", "$3.99"],
                            ["Premium Plan", "7500", "$7.99"]
                        ]
                    },
                    {
                        subtitle: "Credit Usage",
                        content: "Credits can be used for any new features as the platform evolves."
                    }
                ]
            },
            
            {
                title: "2. Credit Usage Guidelines",
                subtitles: [
                    {
                        subtitle: "Credit Validity",
                        content: "Credits do not expire and remain valid as long as your account is active."
                    },
                    {
                        subtitle: "Refund Policy",
                        content: "Credits are non-refundable once purchased. Failed generations due to system errors will have credits automatically refunded to your account."
                    }
                ]
            },
            {
                title: "3. Pricing Plans",
                content: "Wedify is constantly evolving, and we may introduce new features that require credits. We will notify you of any changes to our credit system through the platform and this document.",
                
            }
        ]
    }
];

export default PricingPlanContent;
