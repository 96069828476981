import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Masonry from 'react-masonry-css';
import { getAuth } from 'firebase/auth';
import './Explore.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Spinner from './Spinner';
import { generateAuthToken } from './utils/encryptExternalID';
import ImageDetailsModal from './components/ImageDetailsModal';

const Explore = () => {
  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const generateToken = useCallback(async () => {
    try {
      const token = await generateAuthToken();
      return token;
    } catch (error) {
      console.error("Error generating auth token:", error);
      setError(error.message);
      return null;
    }
  }, []);

  const fetchPictures = useCallback(async () => {
    if (!loading) return;
    
    const maxRetries = 3;
    let retryCount = 0;

    const makeRequestWithFreshToken = async () => {
      const token = await generateToken(); // Get fresh token
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }
      return token;
    };

    try {
      const auth = getAuth();
      if (!auth.currentUser) {
        throw new Error('No authenticated user');
      }

      // Get fresh token for pictures request
      const picturesToken = await makeRequestWithFreshToken();
      const picturesResponse = await axios.get('https://wedify.live/api/images/get/explore', {
        headers: {
          'X-Auth-Token': picturesToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (picturesResponse.data && Array.isArray(picturesResponse.data)) {
        setPictures(picturesResponse.data.map(pic => ({ ...pic, liked: false })));
      } else {
        throw new Error('Invalid data received from the server');
      }

      // Immediately get a fresh token for user ID request
      const userIdToken = await makeRequestWithFreshToken();
      
      while (retryCount < maxRetries) {
        try {
          const userIdResponse = await axios.get(
            `https://wedify.live/api/login/users/get/external/id?authService=firebase&externalClientId=${auth.currentUser.uid}`,
            {
              headers: { 
                'X-Auth-Token': userIdToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              timeout: 3000 // 3 second timeout
            }
          );
          setUserId(userIdResponse.data.id);
          break; // Success, exit retry loop
        } catch (error) {
          retryCount++;
          if (retryCount === maxRetries) {
            throw error; // Throw error after max retries
          }
          // Wait briefly before retrying
          await new Promise(resolve => setTimeout(resolve, 1000));
          // Get fresh token for retry
          const retryToken = await makeRequestWithFreshToken();
          userIdToken = retryToken;
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [loading, generateToken]);

  useEffect(() => {
    fetchPictures();
  }, [fetchPictures]);

  const handleLike = async (imageId) => {
    const auth = getAuth();
    if (!auth.currentUser) {
      console.error('User not authenticated');
      return;
    }

    const picture = pictures.find(p => p.id === imageId);
    if (!picture) return;

    try {
      setPictures(prevPictures => 
        prevPictures.map(p => 
          p.id === imageId ? { ...p, liked: !p.liked } : p
        )
      );

      if (selectedImage && selectedImage.id === imageId) {
        setSelectedImage(prev => ({
          ...prev,
          liked: !prev.liked
        }));
      }

      // Get fresh token right before the request
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      if (!picture.liked) {
        await axios.post(`https://wedify.live/api/favorites/add/external`, null, {
          params: {
            externalClientId: auth.currentUser.uid,
            imageId: imageId,
            authenticationService: 'firebase'
          },
          headers: { 
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
      } else {
        await axios.delete(`https://wedify.live/api/favorites/delete/external`, {
          params: {
            externalClientId: auth.currentUser.uid,
            imageId: imageId,
            authenticationService: 'firebase'
          },
          headers: { 
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
      }
    } catch (error) {
      console.error('Error updating like status:', error);
      setPictures(prevPictures => 
        prevPictures.map(p => 
          p.id === imageId ? { ...p, liked: picture.liked } : p
        )
      );
      if (selectedImage && selectedImage.id === imageId) {
        setSelectedImage(prev => ({
          ...prev,
          liked: picture.liked
        }));
      }
    }
  };

  const handleImageClick = (picture, e) => {
    if (!e.target.closest('.heart-overlay')) {
      setSelectedImage(picture);
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 2
  };

  if (loading) return <Spinner text="Loading Explore Page..." />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="explore-page">
      <h1>Explore Wedding Decor</h1>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {pictures.length > 0 ? (
          pictures.map(picture => (
            <div 
              key={picture.id} 
              className="picture-item"
            >
              <div 
                className="image-container"
                onClick={(e) => handleImageClick(picture, e)}
              >
                <img
                  src={picture.s3Url}
                  alt={`Image ${picture.id}`}
                  className="picture"
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://via.placeholder.com/400x300?text=Image+Not+Available";
                    e.target.alt = 'Image failed to load';
                  }}
                />
                <div 
                  className={`heart-overlay ${picture.liked ? 'liked' : ''}`} 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLike(picture.id);
                  }}
                >
                  <i className={`fa${picture.liked ? 's' : 'r'} fa-heart`}></i>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No pictures found.</p>
        )}
      </Masonry>

      {selectedImage && (
        <ImageDetailsModal
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
          onLike={handleLike}
          isLiked={selectedImage.liked}
        />
      )}
    </div>
  );
};

export default Explore;
