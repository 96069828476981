import React from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ selectedColor, onColorChange }) => {
  return (
    <div className="color-picker-container">
      <SketchPicker
        color={selectedColor}
        onChange={onColorChange}
        className="sketch-picker"
      />
    </div>
  );
};

export default ColorPicker;
