import React from 'react';
import { 
  X, 
  Palette, 
  Flower2 as Flower, 
  Armchair as Chair, 
  MapPin, 
  Sparkles 
} from 'lucide-react';
import './ImageDetailsModal.css';

const ImageDetailsModal = ({ image, onClose, onLike, isLiked }) => {
  const getIconForKey = (key) => {
    switch (key.toLowerCase()) {
      case 'venue':
        return <MapPin size={20} />;
      case 'theme':
        return <Sparkles size={20} />;
      case 'color':
        return <Palette size={20} />;
      case 'flowers':
        return <Flower size={20} />;
      case 'seating':
        return <Chair size={20} />;
      default:
        return null;
    }
  };

  const parseMetadata = (selectedOptions) => {
    if (!selectedOptions) return [];
    
    let metadataValuesList;
    try {
      metadataValuesList = typeof selectedOptions === 'string' 
        ? JSON.parse(selectedOptions).metadataValuesList 
        : selectedOptions.metadataValuesList;
    } catch (error) {
      console.error('Error parsing metadata:', error);
      return [];
    }

    return metadataValuesList?.filter(option => 
      ['venue', 'theme', 'color', 'flowers', 'seating'].includes(option.key)
    ) || [];
  };

  const metadata = parseMetadata(image.selectedOptions);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
       
        
        <div className="modal-grid">
          <div className="image-section">
            <img 
              src={image.s3Url} 
              alt="Wedding decoration" 
              className="modal-image"
            />
            <div 
              className={`heart-overlay ${isLiked ? 'liked' : ''}`} 
              onClick={(e) => {
                e.stopPropagation();
                onLike(image.id);
              }}
            >
              <i className={`fa${isLiked ? 's' : 'r'} fa-heart`}></i>
            </div>
          </div>

          <div className="details-section">
            <h3>Design Details</h3>
            <div className="details-grid">
              {metadata.map((item, index) => (
                <div key={index} className="detail-item">
                  {getIconForKey(item.key)}
                  <div className="detail-text">
                    <span className="detail-label">{item.key.charAt(0).toUpperCase() + item.key.slice(1)}</span>
                    <span className="detail-value">{item.value}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <button className="bottom-back-button" onClick={onClose}>
          Back to Explore
        </button>
      </div>
    </div>
  );
};

export default ImageDetailsModal; 