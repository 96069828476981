import React, { useState, useEffect, useCallback, useRef, useMemo, memo } from 'react';

import ColorPicker from './designCat/ColorPicker';
import Canvas from './Canvas';
import { getClosestColor } from './colorMapper';
import { useDesign } from './DesignContext';
import sendToDiscord from './utils/discordWebhook';
import './Page.css';
import './Design.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CryptoJS from 'crypto-js';
import { generateAuthToken } from './utils/encryptExternalID';
import { getAuth } from 'firebase/auth';
import getImageUrl from './utils/imageUtils';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel, Keyboard, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;

// Keep only one encryption function
const encryptPrompt = (plaintext, key) => {
  try {
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  } catch (error) {
    return null;
  }
};

// Generate token function
const generateToken = async () => {
  try {
    const currentUtcTime = new Date().toISOString();
    const token = await generateAuthToken(currentUtcTime);
    return token;
  } catch (error) {
    return null;
  }
};

const Design = () => {
  const [sliderState, setSliderState] = useState({
    currentSlides: {
      theme: 0,
      flower: 0,
      seating: 0
    },
    slidesToShow: window.innerWidth > 1024 ? 3 : 2
  });
  const sliderRef = useRef(null);

  const [showColorPicker, setShowColorPicker] = useState(false);

  const {
    selectedTheme, setSelectedTheme,
    selectedColor, setSelectedColor,
    selectedFlower, setSelectedFlower,
    selectedSeating, setSelectedSeating,
    selectedSeatingSubOption, setSelectedSeatingSubOption,
    stageVisualizationUrls, setStageVisualizationUrls,
    tableVisualizationUrls, setTableVisualizationUrls,
    stagePrompt, setStagePrompt,
    tablePrompt, setTablePrompt,
    selectedVenue, setSelectedVenue,
  } = useDesign();

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isGenerationComplete, setIsGenerationComplete] = useState(false);
  const [showAddInput, setShowAddInput] = useState({ theme: false, flower: false, seating: false });
  const [newInput, setNewInput] = useState({ theme: '', flower: '', seating: '' });

  const venueOptions = [
    { name: 'Indoor', image: getImageUrl('themesOption/Indoor.jpg') },
    { name: 'Outdoor', image: getImageUrl('themesOption/Outdoor.jpg') }
  ];

  const themeOptions = [
    { name: 'Mandap', image: getImageUrl('themesOption/Mandap.jpg') },
    { name: 'Rustic', image: getImageUrl('themesOption/Rustic.jpg') },
    { name: 'Bohemian', image: getImageUrl('themesOption/Bohemian.jpg') },
    { name: 'Minimalist', image: getImageUrl('themesOption/Minimalistic.jpg') },
    { name: 'Vineyard', image: getImageUrl('themesOption/Vineyard.jpg') },
    { name: 'Beach', image: getImageUrl('themesOption/Beach.jpg') },
    { name: 'Southwestern', image: getImageUrl('themesOption/Southwestern.jpg') },
    { name: 'Luxury', image: getImageUrl('themesOption/Luxury.jpg') },
    { name: 'Classic', image: getImageUrl('themesOption/Classic.jpg') },
    { name: 'Modern', image: getImageUrl('themesOption/Modern.jpg') },
    { name: 'Tropical', image: getImageUrl('themesOption/Tropical.jpg') },
    { name: 'Woodland', image: getImageUrl('themesOption/Woodland.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') },
  ];

  const flowerOptions = [
    { name: 'Wisteria', image: getImageUrl('wisteria.jpg') },
    { name: 'Hydrangeas', image: getImageUrl('hydrangea.jpg') },
    { name: 'Roses', image: getImageUrl('roses.jpg') },
    { name: 'Tulips', image: getImageUrl('tulips.jpg') },
    { name: "Baby's Breath", image: getImageUrl('BabysBreath.jpg') },
    { name: 'Orchids', image: getImageUrl('Orchids.jpg') },
    { name: 'Marigolds', image: getImageUrl('Marigolds.jpg') },
    { name: 'Lilies', image: getImageUrl('Lilies.jpg') },
    { name: 'Peonies', image: getImageUrl('Peonies.jpg') },
    { name: 'Sunflowers', image: getImageUrl('Sunflowers.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') }
  ];

  const seatingOptions = [
    { name: 'Chivari Chair', image: getImageUrl('Chiavari.jpg'), subOptions: ['Gold', 'Silver', 'Wooden'] },
    { name:  'Ghost Chair', description: 'A transparent, elegant chair designed with a smooth, curved backrest and minimalist style. Crafted from clear acrylic, the chair adds a sophisticated, modern touch to any wedding decor while blending seamlessly with its surroundings, ensuring a light and airy atmosphere.', image: getImageUrl('GhostChair.jpg') },    
    { name: 'Bentwood Chair', image: getImageUrl('Bentwood.jpg') },
    { name: 'Louis Chair', image: getImageUrl('Louis.jpg') },
    { name: 'Wooden Chair', image: getImageUrl('Folding.jpg') },
    { name: 'Add', image: getImageUrl('themesOption/add-plus.jpg') }
  ];

  const colorPresets = [
    { name: 'Gold', hex: '#dcc282' },
    { name: 'Silver', hex: '#C0C0C0' },
    { name: 'Blush Pink', hex: '#FFB6C1' },
    { name: 'Sage Green', hex: '#9DC183' },
    { name: 'Navy Blue', hex: '#000080' },
    { name: 'Burgundy', hex: '#800020' },
    { name: 'Dusty Blue', hex: '#6699CC' },
    { name: 'Champagne', hex: '#F7E7CE' },
    { name: 'Lavender', hex: '#E6E6FA' },
    { name: 'Pearl White', hex: '#F0EAD6' },
    { name: 'Terracotta', hex: '#E2725B' },
    { name: 'Rust', hex: '#B7410E' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Emerald', hex: '#50C878' },
    { name: 'Coral', hex: '#FF7F50' },
    { name: 'Mint', hex: '#98FF98' },
    { name: 'Peach', hex: '#FFE5B4' },
    { name: 'Custom', image: getImageUrl('themesOption/colorPicker.png') }
  ];

  const handleVenueChange = (venue) => {
    setSelectedVenue(venue);
  };

  const handleThemeChange = (theme) => {
    setSelectedTheme(theme);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };

  const handleFlowerChange = (flower) => {
    setSelectedFlower(flower);
  };

  const handleSeatingChange = (seating) => {
    setSelectedSeating(seating);
    setSelectedSeatingSubOption('');
    if (seating === 'Ghost Chair') {
      setSelectedSeatingSubOption(seatingOptions.find(option => option.name === 'Ghost Chair').description);
    }
  };

  const handleSeatingSubOptionChange = (subOption) => {
    setSelectedSeatingSubOption(subOption);
  };

  const updateProgress = () => {
    const totalSteps = 5; // Venue, Theme, Color, Flower, Seating, Venue Type
    let completedSteps = 0;
    if (selectedVenue) completedSteps++;
    if (selectedTheme) completedSteps++;
    if (selectedColor !== '#ffffff') completedSteps++;
    if (selectedFlower) completedSteps++;
    if (selectedSeating) completedSteps++;
    setProgress(Math.round((completedSteps / totalSteps) * 100));
  };

  useEffect(() => {
    updateProgress();
  }, [selectedVenue, selectedTheme, selectedColor, selectedFlower, selectedSeating]);

  const checkGenerationStatus = async (generationId) => {
    let attempts = 0;
    const maxAttempts = 30;
    const initialDelay = 1000;
    const maxDelay = 10000;

    const poll = async () => {
      if (attempts >= maxAttempts) {
        throw new Error('Generation timed out');
      }

      attempts++;

      try {
        const token = await generateToken(); // Generate token for each request
        const response = await fetch(`${API_BASE_URL}/api/images/get/image/retrieve`, {
          method: 'GET',
          headers: {
            'imageId': generationId,
            'X-Auth-Token': token, // Add the token to the headers
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('Content-Type');
        const contentLength = response.headers.get('Content-Length');

        if (contentType && contentType.includes('image') && contentLength && parseInt(contentLength) > 0) {
          
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        } else {
      
          const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
          await new Promise(resolve => setTimeout(resolve, delay));
          return poll();
        }
      } catch (error) {

        const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
        await new Promise(resolve => setTimeout(resolve, delay));
        return poll();
      }
    }

    return poll();
  };

  const generateImage = async (prompt, token, userId) => {
    try {
      const encryptedPrompt = encryptPrompt(prompt, SECRET_KEY);
      const generateResponse = await fetch('https://wedify.live/api/images/get/image/generate', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': token,
          'whatYouLookinAt': encryptedPrompt,
          'authId': userId,
          'authenticationService': 'firebase'
        },
        body: JSON.stringify({ prompt: encryptedPrompt })
      });

      if (!generateResponse.ok) {
        const responseText = await generateResponse.text();
        throw new Error(`HTTP error! status: ${generateResponse.status}, body: ${responseText}`);
      }

      const generateData = await generateResponse.json();

      if (generateData && generateData.sdGenerationJob && generateData.sdGenerationJob.generationId) {
        const generationId = generateData.sdGenerationJob.generationId;

        const imageUrl = await checkGenerationStatus(generationId);
        return imageUrl;
      } else {
        throw new Error('No generationId returned');
      }
    } catch (error) {
      throw error;
    }
  };

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  const generateVisualizations = async () => {
    
    // Check if all required fields are selected
    if (!selectedVenue || !selectedTheme || !selectedColor || !selectedFlower || !selectedSeating) {
      console.error('Not all required fields are selected');
      return;
    }

    setLoading(true);
    setIsGenerationComplete(false);
    setProgress(0);

    // Get current Firebase user
    const auth = getAuth();
    const currentUser = auth.currentUser;
    
    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const namedColor = getClosestColor(selectedColor);
    let themeDescription = selectedTheme === 'Mandap' ? '' : `${selectedTheme} `;
    
    let seatingDescription = selectedSeating;
    if (selectedSeating === 'Ghost Chair') {
      seatingDescription = selectedSeatingSubOption;
    } else if (selectedSeatingSubOption) {
      seatingDescription = `${selectedSeating} (${selectedSeatingSubOption})`;
    }

    const newStagePrompt = `A wide angle view of a ${selectedTheme} ${selectedVenue} wedding stage setup with ${namedColor} color theme and ${selectedFlower} floral arrangements`;
    const newTablePrompt = `A 60-degree angle from above and slightly diagonal towards the upper-left captures the entire ${themeDescription}${selectedVenue} wedding table setup, featuring a ${namedColor} color theme, adorned with ${selectedFlower} floral arrangements, and completed with ${seatingDescription} chairs`;

    setStagePrompt(newStagePrompt);
    setTablePrompt(newTablePrompt);

    try {
      setProgress(10);

      // Generate auth token
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      // Generate both images in parallel
      const [stageImageUrl, tableImageUrl] = await Promise.all([
        generateImage(newStagePrompt, token, currentUser.uid),
        generateImage(newTablePrompt, token, currentUser.uid)
      ]);

      setStageVisualizationUrls([stageImageUrl]);
      setTableVisualizationUrls([tableImageUrl]);

      setIsGenerationComplete(true);
      setProgress(100);
    } catch (error) {
      console.error('Error generating visualizations:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setSliderState(prev => ({
        ...prev,
        slidesToShow: window.innerWidth > 1024 ? 3 : 2
      }));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);

  // Define HoverArea component with PropTypes inside
  const HoverArea = memo(({ direction }) => {
    const handleMouseEnter = useCallback(() => {
      if (sliderRef.current) {
        if (direction === 'next') {
          sliderRef.current.slickNext();
        } else {
          sliderRef.current.slickPrev();
        }
      }
    }, [direction]);

    return (
      <div 
        className={`hover-area hover-area-${direction}`}
        onMouseEnter={handleMouseEnter}
      >
        <div className="hover-indicator">
          {direction === 'next' ? '>' : '<'}
        </div>
      </div>
    );
  });

  // Add PropTypes inside the component
  HoverArea.propTypes = {
    direction: PropTypes.oneOf(['next', 'prev']).isRequired
  };

  const handleBeforeChange = useCallback((oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  }, []);

  // Create refs for each slider
  const themeSliderRef = useRef(null);
  const flowerSliderRef = useRef(null);
  const seatingSliderRef = useRef(null);

  // Memoize the hover handlers
  const handlePrevHover = useCallback((sliderRef) => () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  }, []);

  const handleNextHover = useCallback((sliderRef) => () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, []);

  // Define separate settings for each slider
  const themeSliderSettings = {
    modules: [Navigation, Autoplay, Pagination],
    spaceBetween: 1,
    slidesPerView: 3,
    speed: 200,
    className: "theme-swiper",
    loop: true,
    allowTouchMove: false,
    pagination: {
      el: '.theme-pagination',
      clickable: true,
      type: 'bullets',
      dynamicBullets: true,
      dynamicMainBullets: 3
    }
  };

  const flowerSliderSettings = {
    ...themeSliderSettings,
    pagination: {
      el: '.flower-pagination',
      clickable: true,
      type: 'bullets',
      dynamicBullets: true,
      dynamicMainBullets: 3
    }
  };

  const seatingSliderSettings = {
    ...themeSliderSettings,
    pagination: {
      el: '.seating-pagination',
      clickable: true,
      type: 'bullets',
      dynamicBullets: true,
      dynamicMainBullets: 3
    }
  };

  const handleAddClick = (type) => {
    setShowAddInput(prev => ({ ...prev, [type]: true }));
  };

  const handleInputChange = (e, type) => {
    setNewInput(prev => ({ ...prev, [type]: e.target.value }));
  };

  const handleNewInputSubmit = async (type) => {
    if (!newInput[type].trim()) {
      return;
    }

    // Get current user
    const auth = getAuth();
    const user = auth.currentUser;
    const userEmail = user ? user.email : 'Anonymous';

    // Prepare message for Discord
    const message = {
      username: "Wedify Bot",
      content: `New ${type} suggestion from ${userEmail}`,
      embeds: [{
        title: `New ${type} Suggestion`,
        description: `Suggested ${type}: ${newInput[type]}`,
        fields: [
          {
            name: 'Category',
            value: `Wedding Design ${type.charAt(0).toUpperCase() + type.slice(1)}`
          },
          {
            name: 'Submitted by',
            value: userEmail
          }
        ],
        color: 3066993 // Green color in decimal
      }]
    };

    try {
      await sendToDiscord(message);
      setNewInput(prev => ({ ...prev, [type]: '' }));
      setShowAddInput(prev => ({ ...prev, [type]: false }));
      alert('Thank you for your suggestion! We will review it shortly.');
    } catch (error) {
      console.error('Error sending suggestion:', error);
      alert('Sorry, there was an error submitting your suggestion. Please try again later.');
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderProgressBar = () => {
    const steps = [
      { num: 1, name: 'Venue' },
      { num: 2, name: 'Theme' },
      { num: 3, name: 'Color' },
      { num: 4, name: 'Flower' },
      { num: 5, name: 'Seating' },
      { num: 6, name: 'Preview' }
    ];

    return (
      <div className="mobile-progress-bar">
        {steps.map((step) => (
          <div 
            key={step.num}
            className={`step ${currentStep >= step.num ? 'completed' : ''} ${currentStep === step.num ? 'active' : ''}`}
            onClick={() => setCurrentStep(step.num)}
          >
            <div className="step-number">{step.num}</div>
            <div className="step-name">{step.name}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderContinueButton = (condition, nextStep) => {
    if (condition) {
      if (nextStep === 6) {
        return (
          <button
            className="continue-button"
            onClick={async () => {
              setCurrentStep(nextStep);
              await generateVisualizations();
            }}
            disabled={loading}
          >
            {loading ? 'Generating...' : 'Generate'}
          </button>
        );
      }
      return (
        <button
          className="continue-button"
          onClick={() => setCurrentStep(nextStep)}
        >
          Continue
        </button>
      );
    }
    return null;
  };

  const renderMobileStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Venue Type</h2>
            </div>
            <div className="mobile-options-grid">
              {venueOptions.map((venue) => (
                <div 
                  key={venue.name}
                  onClick={() => handleVenueChange(venue.name)}
                  className={`mobile-option-item ${selectedVenue === venue.name ? 'selected' : ''}`}
                >
                  <img src={venue.image} alt={venue.name} />
                  <p>{venue.name}</p>
                </div>
              ))}
            </div>
            {renderContinueButton(selectedVenue, 2)}
          </>
        );

      case 2:
        return (
          <>
            <div className="step-header">
              <h2>Select Your Theme</h2>
            </div>
            <div className="mobile-options-grid">
              {themeOptions.map((theme) => (
                <div 
                  key={theme.name}
                  onClick={() => {
                    if (theme.name === 'Add') {
                      handleAddClick('theme');
                      return;
                    }
                    handleThemeChange(theme.name);
                  }}
                  className={`mobile-option-item ${selectedTheme === theme.name ? 'selected' : ''}`}
                >
                  <img src={theme.image} alt={theme.name} />
                  <p>{theme.name}</p>
                </div>
              ))}
            </div>
            {showAddInput.theme && (
              <div className="add-theme-overlay">
                <div className="add-theme-input">
                  <h4>What other theme should we add?</h4>
                  <input 
                    type="text" 
                    value={newInput.theme}
                    onChange={(e) => handleInputChange(e, 'theme')}
                    placeholder="Suggest a new theme..."
                    autoFocus
                  />
                  <div className="add-theme-buttons">
                    <button onClick={() => setShowAddInput(prev => ({ ...prev, theme: false }))}>Cancel</button>
                    <button onClick={() => handleNewInputSubmit('theme')}>Submit</button>
                  </div>
                </div>
              </div>
            )}
            {renderContinueButton(selectedTheme, 3)}
          </>
        );

      case 3:
        return (
          <>
            <div className="step-header">
              <h2>Choose Your Color</h2>
            </div>
            <div className="color-picker-container">
              {!showColorPicker ? (
                <div className="color-presets-container">
                  {colorPresets.map((color) => (
                    <div 
                      key={color.name}
                      onClick={() => color.name === 'Custom' 
                        ? setShowColorPicker(true) 
                        : handleColorChange({ hex: color.hex })}
                      className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                    >
                      {color.name === 'Custom' ? (
                        <>
                          <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                          <p>Custom Color</p>
                        </>
                      ) : (
                        <>
                          <div 
                            className="color-swatch" 
                            style={{ backgroundColor: color.hex }}
                          />
                          <p>{color.name}</p>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <button 
                    onClick={() => setShowColorPicker(false)}
                    className="back-to-presets"
                  >
                    ← Back to Colors
                  </button>
                  <ColorPicker
                    selectedColor={selectedColor} 
                    onColorChange={handleColorChange}
                    className="color-picker"
                  />
                </div>
              )}
            </div>
            {renderContinueButton(selectedColor !== '#ffffff', 4)}
          </>
        );

      case 4:
        return (
          <>
            <div className="step-header">
              <h2>Select Your Flowers</h2>
            </div>
            <div className="mobile-options-grid">
              {flowerOptions.map((flower) => (
                <div 
                  key={flower.name}
                  onClick={() => {
                    if (flower.name === 'Add') {
                      handleAddClick('flower');
                      return;
                    }
                    handleFlowerChange(flower.name);
                  }}
                  className={`mobile-option-item ${selectedFlower === flower.name ? 'selected' : ''}`}
                >
                  <img src={flower.image} alt={flower.name} />
                  <p>{flower.name}</p>
                </div>
              ))}
            </div>
            {showAddInput.flower && (
              <div className="add-theme-overlay">
                <div className="add-theme-input">
                  <h4>What other flower should we add?</h4>
                  <input 
                    type="text" 
                    value={newInput.flower}
                    onChange={(e) => handleInputChange(e, 'flower')}
                    placeholder="Suggest a new flower..."
                    autoFocus
                  />
                  <div className="add-theme-buttons">
                    <button onClick={() => setShowAddInput(prev => ({ ...prev, flower: false }))}>Cancel</button>
                    <button onClick={() => handleNewInputSubmit('flower')}>Submit</button>
                  </div>
                </div>
              </div>
            )}
            {renderContinueButton(selectedFlower, 5)}
          </>
        );

      case 5:
        return (
          <>
            <div className="step-header">
              <h2>Select Your Seating</h2>
            </div>
            <div className="mobile-options-grid">
              {seatingOptions.map((seating) => (
                <div 
                  key={seating.name}
                  onClick={() => {
                    if (seating.name === 'Add') {
                      handleAddClick('seating');
                      return;
                    }
                    handleSeatingChange(seating.name);
                  }}
                  className={`mobile-option-item ${selectedSeating === seating.name ? 'selected' : ''}`}
                >
                  <img src={seating.image} alt={seating.name} />
                  <p>{seating.name}</p>
                  {selectedSeating === seating.name && seating.subOptions && (
                    <select
                      value={selectedSeatingSubOption}
                      onChange={(e) => handleSeatingSubOptionChange(e.target.value)}
                      className="seating-sub-option"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="">Select Style</option>
                      {seating.subOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            </div>
            {showAddInput.seating && (
              <div className="add-theme-overlay">
                <div className="add-theme-input">
                  <h4>What other seating option should we add?</h4>
                  <input 
                    type="text" 
                    value={newInput.seating}
                    onChange={(e) => handleInputChange(e, 'seating')}
                    placeholder="Suggest a new seating option..."
                    autoFocus
                  />
                  <div className="add-theme-buttons">
                    <button onClick={() => setShowAddInput(prev => ({ ...prev, seating: false }))}>Cancel</button>
                    <button onClick={() => handleNewInputSubmit('seating')}>Submit</button>
                  </div>
                </div>
              </div>
            )}
            {renderContinueButton(selectedSeating, 6)}
          </>
        );

      case 6:
        return (
          <>
            <Canvas 
              progress={progress}
              loading={loading}
              setLoading={setLoading}
              generateVisualizations={generateVisualizations}
              isGenerationComplete={isGenerationComplete}
            />
          </>
        );

      default:
        return null;
    }
  };

  const swiperRef = useRef(null);
  const intervalRef = useRef(null);

  const startAutoplay = (direction) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      
      // Immediate first slide
      if (direction === 'left') {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }

      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      // Set new interval for continuous sliding
      intervalRef.current = setInterval(() => {
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
      }, 300); // Reduced interval for more fluid movement
    }
  };

  const stopAutoplay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleMouseEnter = (direction) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      if (direction === 'left') {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }
    }
  };

  // At the top of your component, create separate interval refs
  const themeIntervalRef = useRef(null);
  const flowerIntervalRef = useRef(null);
  const seatingIntervalRef = useRef(null);

  // Create separate autoplay functions for each section
  const startThemeAutoplay = (direction) => {
    if (themeSliderRef.current && themeSliderRef.current.swiper) {
      const swiper = themeSliderRef.current.swiper;
      
      // Immediate first slide
      if (direction === 'left') {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }

      // Clear any existing interval
      if (themeIntervalRef.current) {
        clearInterval(themeIntervalRef.current);
      }

      // Set new interval
      themeIntervalRef.current = setInterval(() => {
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
      }, 300);
    }
  };

  const startFlowerAutoplay = (direction) => {
    if (flowerSliderRef.current && flowerSliderRef.current.swiper) {
      const swiper = flowerSliderRef.current.swiper;
      
      if (direction === 'left') {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }

      if (flowerIntervalRef.current) {
        clearInterval(flowerIntervalRef.current);
      }

      flowerIntervalRef.current = setInterval(() => {
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
      }, 300);
    }
  };

  const startSeatingAutoplay = (direction) => {
    if (seatingSliderRef.current && seatingSliderRef.current.swiper) {
      const swiper = seatingSliderRef.current.swiper;
      
      if (direction === 'left') {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }

      if (seatingIntervalRef.current) {
        clearInterval(seatingIntervalRef.current);
      }

      seatingIntervalRef.current = setInterval(() => {
        if (direction === 'left') {
          swiper.slidePrev();
        } else {
          swiper.slideNext();
        }
      }, 300);
    }
  };

  // Separate stop functions
  const stopThemeAutoplay = () => {
    if (themeIntervalRef.current) {
      clearInterval(themeIntervalRef.current);
      themeIntervalRef.current = null;
    }
  };

  const stopFlowerAutoplay = () => {
    if (flowerIntervalRef.current) {
      clearInterval(flowerIntervalRef.current);
      flowerIntervalRef.current = null;
    }
  };

  const stopSeatingAutoplay = () => {
    if (seatingIntervalRef.current) {
      clearInterval(seatingIntervalRef.current);
      seatingIntervalRef.current = null;
    }
  };

  return (
    <div className="design-wrapper">
    {isMobile ? (
      <div className="mobile-design">
        <div className="mobile-content">
          {renderProgressBar()}
          <div className="mobile-step">
            {renderMobileStep()}
          </div>
        </div>
      </div>
    )  : (
        // Desktop Layout
        <div className="design-page">
          <div className="design-container">
            <h2 className="page-title">Design Your Wedding</h2>
            {/* Venue Section */}
            <div className="design-section venue">
              <h3>Select Venue</h3>
              <div className="venue-options">
                {venueOptions.map((venue) => (
                  <div 
                    key={venue.name} 
                    onClick={() => handleVenueChange(venue.name)} 
                    className={`venue-item ${selectedVenue === venue.name ? 'selected' : ''}`}
                  >
                    <img src={venue.image} alt={venue.name} className="venue-image" />
                    <p>{venue.name}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Theme Section */}
            <div className="design-section theme">
              <h3>Select Theme</h3>
              <div className="slider-container">
                <div 
                  className="hover-zone hover-zone-left"
                  onMouseEnter={() => startThemeAutoplay('left')}
                  onMouseLeave={stopThemeAutoplay}
                />
                <Swiper
                  ref={themeSliderRef}
                  {...themeSliderSettings}
                >
                  {themeOptions.map((theme) => (
                    <SwiperSlide key={theme.name}>
                      <div 
                        onClick={() => {
                          if (theme.name === 'Add') {
                            handleAddClick('theme');
                            return;
                          }
                          handleThemeChange(theme.name);
                        }}
                        className={`carousel-item ${selectedTheme === theme.name ? 'selected' : ''}`}
                      >
                        <img src={theme.image} alt={theme.name} className="carousel-image" />
                        <p>{theme.name}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="swiper-pagination theme-pagination"></div>
                </Swiper>
                <div 
                  className="hover-zone hover-zone-right"
                  onMouseEnter={() => startThemeAutoplay('right')}
                  onMouseLeave={stopThemeAutoplay}
                />
              </div>
              {showAddInput.theme && (
                <div className="add-theme-overlay">
                  <div className="add-theme-input">
                    <h4>What other theme should we add?</h4>
                    <input 
                      type="text" 
                      value={newInput.theme}
                      onChange={(e) => handleInputChange(e, 'theme')}
                      placeholder="Suggest a new theme..."
                      autoFocus
                    />
                    <div className="add-theme-buttons">
                      <button onClick={() => setShowAddInput(prev => ({ ...prev, theme: false }))}>Cancel</button>
                      <button onClick={() => handleNewInputSubmit('theme')}>Submit</button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Color Section */}
            <div className="design-section colors">
              <h3>Select Color</h3>
              {!showColorPicker ? (
                // Preset Colors View
                <div className="color-presets-container">
                  {colorPresets.map((color) => (
                    <div 
                      key={color.name}
                      onClick={() => color.name === 'Custom' 
                        ? setShowColorPicker(true) 
                        : handleColorChange({ hex: color.hex })}
                      className={`color-preset-item ${selectedColor === color.hex ? 'selected' : ''}`}
                    >
                      {color.name === 'Custom' ? (
                        <>
                          <img src={color.image} alt="Custom color" className="color-wheel-icon" />
                          <p>Custom Color</p>
                        </>
                      ) : (
                        <>
                          <div 
                            className="color-swatch" 
                            style={{ backgroundColor: color.hex }}
                          />
                          <p>{color.name}</p>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                // Color Picker View
                <div className="color-picker-container">
                  <button 
                    onClick={() => setShowColorPicker(false)}
                    className="back-to-presets-btn"
                  >
                    ← Back to Presets
                  </button>
                  <ColorPicker 
                    selectedColor={selectedColor} 
                    onColorChange={handleColorChange} 
                  />
                </div>
              )}
            </div>

            {/* Flower Section */}
            <div className="design-section flowers">
              <h3>Select Flowers</h3>
              <div className="slider-container">
                <div 
                  className="hover-zone hover-zone-left"
                  onMouseEnter={() => startFlowerAutoplay('left')}
                  onMouseLeave={stopFlowerAutoplay}
                />
                <Swiper
                  ref={flowerSliderRef}
                  {...flowerSliderSettings}
                >
                  {flowerOptions.map((flower) => (
                    <SwiperSlide key={flower.name}>
                      <div 
                        onClick={() => {
                          if (flower.name === 'Add') {
                            handleAddClick('flower');
                            return;
                          }
                          handleFlowerChange(flower.name);
                        }}
                        className={`carousel-item ${selectedFlower === flower.name ? 'selected' : ''}`}
                      >
                        <img src={flower.image} alt={flower.name} className="carousel-image" />
                        <p>{flower.name}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="swiper-pagination flower-pagination"></div>
                </Swiper>
                <div 
                  className="hover-zone hover-zone-right"
                  onMouseEnter={() => startFlowerAutoplay('right')}
                  onMouseLeave={stopFlowerAutoplay}
                />
              </div>
              {showAddInput.flower && (
                <div className="add-theme-overlay">
                  <div className="add-theme-input">
                    <h4>What other flower should we add?</h4>
                    <input 
                      type="text" 
                      value={newInput.flower}
                      onChange={(e) => handleInputChange(e, 'flower')}
                      placeholder="Suggest a new flower..."
                      autoFocus
                    />
                    <div className="add-theme-buttons">
                      <button onClick={() => setShowAddInput(prev => ({ ...prev, flower: false }))}>Cancel</button>
                      <button onClick={() => handleNewInputSubmit('flower')}>Submit</button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Seating Section */}
            <div className="design-section seating">
              <h3>Select Seating</h3>
              <div className="slider-container">
                <div 
                  className="hover-zone hover-zone-left"
                  onMouseEnter={() => startSeatingAutoplay('left')}
                  onMouseLeave={stopSeatingAutoplay}
                />
                <Swiper
                  ref={seatingSliderRef}
                  {...seatingSliderSettings}
                >
                  {seatingOptions.map((seating) => (
                    <SwiperSlide key={seating.name}>
                      <div 
                        onClick={() => {
                          if (seating.name === 'Add') {
                            handleAddClick('seating');
                            return;
                          }
                          handleSeatingChange(seating.name);
                        }}
                        className={`carousel-item ${selectedSeating === seating.name ? 'selected' : ''}`}
                      >
                        <img src={seating.image} alt={seating.name} className="carousel-image" />
                        <p>{seating.name}</p>
                        {selectedSeating === seating.name && seating.subOptions && (
                          <select
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => handleSeatingSubOptionChange(e.target.value)}
                            value={selectedSeatingSubOption}
                            className="seating-sub-option"
                          >
                            <option value="">Select Style</option>
                            {seating.subOptions.map((subOption) => (
                              <option key={subOption} value={subOption}>{subOption}</option>
                            ))}
                          </select>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="swiper-pagination seating-pagination"></div>
                </Swiper>
                <div 
                  className="hover-zone hover-zone-right"
                  onMouseEnter={() => startSeatingAutoplay('right')}
                  onMouseLeave={stopSeatingAutoplay}
                />
              </div>
              {showAddInput.seating && (
                <div className="add-theme-overlay">
                  <div className="add-theme-input">
                    <h4>What other seating option should we add?</h4>
                    <input 
                      type="text" 
                      value={newInput.seating}
                      onChange={(e) => handleInputChange(e, 'seating')}
                      placeholder="Suggest a new seating option..."
                      autoFocus
                    />
                    <div className="add-theme-buttons">
                      <button onClick={() => setShowAddInput(prev => ({ ...prev, seating: false }))}>Cancel</button>
                      <button onClick={() => handleNewInputSubmit('seating')}>Submit</button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Generate Button Section */}
            <div className="design-section generate">
              <div className="visualization-container">
                <button
                  onClick={generateVisualizations}
                  disabled={!selectedVenue || !selectedTheme || !selectedColor || !selectedFlower || !selectedSeating || loading}
                  className="visualization-button"
                >
                  {loading ? 'Generating...' : isGenerationComplete ? 'Regenerate' : 'Generate Visualizations'}
                </button>
                
                {loading && (
                  <div className="progress-container">
                    <div className="progress-bar">
                      <div className="progress" style={{ width: `${progress}%` }}></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Canvas Section */}
          <div className="canvas-container">
            <Canvas 
              progress={progress} 
              loading={loading}
              setLoading={setLoading} 
              generateVisualizations={generateVisualizations}
              isGenerationComplete={isGenerationComplete}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Design;
