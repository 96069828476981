import React, { createContext, useState, useContext } from 'react';

const DesignContext = createContext();

export const DesignProvider = ({ children }) => {
  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [selectedFlower, setSelectedFlower] = useState('');
  const [selectedSeating, setSelectedSeating] = useState('');
  const [selectedSeatingSubOption, setSelectedSeatingSubOption] = useState('');
  const [stageVisualizationUrls, setStageVisualizationUrls] = useState([]);
  const [tableVisualizationUrls, setTableVisualizationUrls] = useState([]);
  const [stagePrompt, setStagePrompt] = useState('');
  const [tablePrompt, setTablePrompt] = useState('');
  const [selectedVenue, setSelectedVenue] = useState('');
  const [selectedGreenery, setSelectedGreenery] = useState('');
  const [bouquetVisualizationUrls, setBouquetVisualizationUrls] = useState([]);
  const [boutonniereVisualizationUrls, setBoutonniereVisualizationUrls] = useState([]);
  const [bouquetPrompt, setBouquetPrompt] = useState('');
  const [boutonnierePrompt, setBoutonnierePrompt] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedTier, setSelectedTier] = useState('');
  const [selectedShape, setSelectedShape] = useState('');
  const [selectedFlavor, setSelectedFlavor] = useState('');
  const [selectedDecoration, setSelectedDecoration] = useState('');
  const [selectedDecorationSubOption, setSelectedDecorationSubOption] = useState('');
  const [cakePrompt, setCakePrompt] = useState('');
  const [cakeVisualizationUrls, setCakeVisualizationUrls] = useState([]);

  const [isGenerationComplete, setIsGenerationComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  // New: Function to generate designs
  const generateDesigns = async () => {
    try {
      // Mock API call or logic to generate designs based on selected options
      const generatedStageImageUrl = 'https://example.com/stage-image.jpg';
      const generatedTableImageUrl = 'https://example.com/table-image.jpg';

      setStageVisualizationUrls([generatedStageImageUrl]);
      setTableVisualizationUrls([generatedTableImageUrl]);

    } catch (error) {
      console.error('Error generating designs:', error);
    }
  };

  const clearState = () => {
    setSelectedTheme('');
    setSelectedColor('#ffffff');
    setSelectedFlower('');
    setSelectedSeating('');
    setSelectedSeatingSubOption('');
    setStageVisualizationUrls([]);
    setTableVisualizationUrls([]);
    setStagePrompt('');
    setTablePrompt('');
    setSelectedVenue('');
    setSelectedGreenery('');
    setSelectedSize('');
    setSelectedTier('');
    setSelectedShape('');
    setSelectedFlavor('');
    setSelectedDecoration('');
    setSelectedDecorationSubOption('');
    setCakePrompt('');
    setCakeVisualizationUrls([]);
    
  };

  const value = {
    selectedTheme,
    setSelectedTheme,
    selectedColor,
    setSelectedColor,
    selectedFlower,
    setSelectedFlower,
    selectedSeating,
    setSelectedSeating,
    selectedSeatingSubOption,
    setSelectedSeatingSubOption,
    stageVisualizationUrls,
    setStageVisualizationUrls,
    tableVisualizationUrls,
    setTableVisualizationUrls,
    stagePrompt,
    setStagePrompt,
    tablePrompt,
    setTablePrompt,
    generateDesigns, // Exporting generateDesigns
    selectedVenue,
    setSelectedVenue,
    clearState,
    selectedGreenery,
    setSelectedGreenery,
    bouquetVisualizationUrls,
    setBouquetVisualizationUrls,
    boutonniereVisualizationUrls,
    setBoutonniereVisualizationUrls,
    bouquetPrompt,
    setBouquetPrompt,
    boutonnierePrompt,
    setBoutonnierePrompt,
    selectedSize,
    setSelectedSize,
    selectedTier,
    setSelectedTier,
    selectedShape,
    setSelectedShape,
    selectedFlavor,
    setSelectedFlavor,
    selectedDecoration,
    setSelectedDecoration,
    selectedDecorationSubOption,
    setSelectedDecorationSubOption,
    cakePrompt,
    setCakePrompt,
    cakeVisualizationUrls,
    setCakeVisualizationUrls,
    isGenerationComplete,
    setIsGenerationComplete,
    loading,
    setLoading,
    progress,
    setProgress
  };

  return (
    <DesignContext.Provider value={value}>
      {children}
    </DesignContext.Provider>
  );
};

export const useDesign = () => useContext(DesignContext);
